<template>
  <div class="addMoreAddress">
    <van-nav-bar
      title="添加多个地址"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="address_shibie">
      <div
        class="list"
        v-for="(item, index) in list"
        :key="index"
      >
        <van-field
          v-if="!item.address.areaCode || item.address.areaCode.legth <= 0"
          v-model="item.str"
          rows="3"
          type="textarea"
          placeholder="请输入地址信息 点击识别按钮可自动识别"
        />
        <van-cell v-else class="address_list_van-cell">
          <template #title>
            <div style="display: flex;">
              <div style="flex:1; text-align: left;"><van-tag type="danger" plain v-if="item.chongfu == 1">重复</van-tag> {{item.address.name}} {{item.address.mobile}}</div>
              <van-tag type="danger" plain style="margin-right: 20px;" v-if="item.chongfu == 1 ? false : true" @click="editItem(index)">修改</van-tag>
              <van-tag type="danger" plain @click="delItem(index)">删除</van-tag>
            </div>
            <div>
              {{item.address.province}} {{item.address.city}} {{item.address.area}} {{item.address.street}}
            </div>
          </template>
        </van-cell>
        <div></div>
      </div>
      <div class="shibie_btn">
        <div class="shibie_btn_list">
          <van-icon name="add-o" size="30px" style="margin-right: 20px;" @click="add" />
          <van-icon name="minus" size="20px" class="remove_list" @click="del" />
        </div>
        <van-button size="small" plain type="primary" native-type="button" hairline @click="parseAddress">识别</van-button>
      </div>
      <div class="save_btn">
        <van-button round type="danger" v-if="chongfu == 0" block @click="save">保存</van-button>
        <van-button round type="danger" v-if="chongfu == 1" block @click="chongfuComfime">确定</van-button>
      </div>
    </div>

    <transition name="van-slide-right"> 
      <div class="demo_cont" v-show="showshibieAddress">
        <van-form @submit="onSaveAddress" class="demo-animate-block">
          <van-icon name="close" size="26" color="#fff" @click="showshibieAddress = false" style="position: absolute; top: -30px;right: 0px;"></van-icon>
          <van-field
            name="name"
            v-model="address.name"
            label="姓名"
            placeholder="收货人姓名"
            :rules="[{ required: true, message: '请输入收货人姓名' }]"
          />
          <van-field
            name="mobile"
            v-model="address.mobile"
            label="电话"
            type="tel"
            placeholder="收货人手机号"
            :rules="[{ validator, message: '请输入收货人手机号' }]"
          />
          <van-field
            readonly
            clickable
            name="area"
            v-model="address.areastr"
            label="地区"
            placeholder="点击选择省市区"
            @click="showArea = true"
            :rules="[{ valiarea, message: '请选择省市区' }]"
          />
          <van-field
            name="street"
            v-model="address.street"
            label="详细地址"
            placeholder="街道门牌、楼层房间号等信息"
            :rules="[{ required: true, message: '请输入详细地址' }]"
          />
          <div class="address_shibie">
            <van-field
              ref="addressStr"
              v-model="address.str"
              rows="3"
              type="textarea"
              placeholder="请输入地址信息 可自动识别"
            />
            <van-button size="small" plain type="primary" native-type="button" hairline @click="parseAddress">识别</van-button>
          </div>
          <van-popup v-model="showArea" position="bottom">
            <van-area
              :value="address.areaCode"
              :area-list="areaList"
              @confirm="onConfirm"
              @cancel="showArea = false"
            />
          </van-popup>
          <div style="margin: 16px;">
            <van-button round block type="info" :loading="saveAddBtn" :native-type="!saveAddBtn ? 'submit' : 'button'">保存</van-button>
          </div>
        </van-form>
      </div>
    </transition>
  </div>
</template>
<script>
import areaList from "@/components/area.js";
import SmartAddress from 'react-smart-address';
export default {
  name: "addMoreAddress",
  data() {
    return {
      shareid: 0,
      merchid: 0,
      list: [
        {
          str: '',
          address: {}
        }
      ],
      areaList: areaList,
      address: {},
      saveAddBtn: false,
      showshibieAddress: false,
      showArea: false,
      chongfu: 0, // 是否有重复地址
    }
  },
  mounted() {
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "添加多个地址");
    if (localStorage.getItem("uploadsList")) {
      let list = [];
      list = JSON.parse(localStorage.getItem("uploadsList"));
      if (list.length > 0) {
        this.list = [];
        list.forEach(item => {
          console.log(item);
          this.list.push({
            str: item,
            address: {}
          })
        })
      }
      localStorage.removeItem("uploadsList");
    }
  },
  methods: {
    validator(val) {
      return /1\d{10}$/.test(val); // 校验函数返回 true 表示校验通过，false 表示不通过
    },
    valiarea(val) {
      let area = false;
      let areaArr = [];
      if (val) {
        if (val.indexOf("/") !== -1) {
          areaArr = val.split("/");
          if (areaArr.length >= 3) {
            area = true;
          }
        }
      }
      return area;
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back();
    },
    // 新增列表
    add() {
      this.list.push({str: '', address: {}});
    },
    // 删除列表
    del() {
      if (this.list.length <= 1) {
        this.$toast("至少添加一个地址");
        this.list = [{str: '', address: {}}];
        return false;
      }
      this.list.pop();
    },
    // 识别地址
    parseAddress () {
      let a = null;
      a = 1;
      const toast = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "已识别"+a+"条地址",
      });
      if (this.list && this.list.length > 0) {
        this.list.forEach((item, index) => {
          if (item.str && item.str.length > 10) {
            let address = null;
            address = this.doparseAddress(item.str);
            console.log(address);
            if (address.areaCode) {
              this.list[index].address = address;
              toast.message = "已识别"+a+"条地址";
              a++;
            }
            if(index == (this.list.length-1)) {
              toast.clear();
            }
          }
        })
      }
    },
    // 执行识别地址
    doparseAddress(str) {
      let address = null;
      address = SmartAddress.smart(str);
      let name = "";
      let areaCode = "";
      if (address.name) {
        name = address.name;
      }else {
        name = address.residueaddress;
      }
      if (address.countyCode) {
        areaCode = address.countyCode;
      }else {
        if (address.cityCode) {
          areaCode = address.cityCode;
        }else {
          if (address.provinceCode) {
            areaCode = address.provinceCode;
          }
        }
      }

      const addParse = {
        name: name,
        mobile: address.mobile,
        province: address.province,
        city: address.city,
        area: address.county,
        areaCode: areaCode,
        street: address.address
      }
      return addParse;
    },

    /**
     * 修改地址
     */
    editItem(index) {
      console.log(index);
      this.address = this.list[index].address;
      this.address.areastr = this.address.province + "/" + this.address.city + "/" + this.address.area;
      this.address.index = index;
      this.showshibieAddress = true;
      // 赵德安 13555844719 辽宁省 沈阳市 沈北新区 蒲河路27号人杰水岸一期菜鸟驿站
    },

    /**
     * 删除地址
     */
    delItem(index) {
      console.log(index);
      this.$dialog.alert({
        message: "确定要删除该地址？",
        showCancelButton: true
      })
      .then(() => {
        // on confirm
        if (this.list.length > 1) {
          this.list.splice(index, 1);

          let address = null;
          address = localStorage.getItem("DK_ADDRESS");
          if(address && address.length > 0) {
            address = JSON.parse(address);

            if(address.length > 0) {
              address.splice(index, 1);
              localStorage.setItem("DK_ADDRESS", JSON.stringify(address));
            }
          }

          const addressId = [];
          const chongfuIndex = [];
          this.list.forEach((item, index) => {
            this.list[index].chongfu = 0;
            if (addressId.indexOf(item.id) >= 0) {
              chongfuIndex.push(item.id);
            }
            addressId.push(item.id);
          })

          if (chongfuIndex.length > 0) {
            chongfuIndex.forEach(item => {
              this.list.forEach((val, key) => {
                if (val.id == item) {
                  this.list[key].chongfu = 1;
                }
              })
            })
          }
          
        }else{
          this.list = [{str: "", address: {}}];
          this.$toast("至少添加一个地址");
        }
      })
      .catch(() => {
        // on cancel
      });
    },

    /**
     * 保存识别的地址
     */
    onSaveAddress() {
      console.log("保存识别的地址");
      if (!this.address.name) {
        this.$toast("请填写收货人姓名")
        return false;
      }
      if (!this.address.mobile) {
        this.$toast("请填写手机号")
        return false;
      }
      if (!this.address.province) {
        this.$toast("请选择省")
        return false;
      }
      if (!this.address.city) {
        this.$toast("请选择市")
        return false;
      }
      if (!this.address.area) {
        this.$toast("请选择区县")
        return false;
      }
      if (!this.address.street) {
        this.$toast("请填写详细地址")
        return false;
      }
      let index = -1;
      index = this.address.index;
      this.list[index].address = this.address;
      this.showshibieAddress = false;
      if (this.list[index].id && parseInt(this.list[index].id) > 0) {
        this.upload();
      }
    },

    /**
     * 更新表数据库中的地址
     */
    upload() {
      let _that = null;
      _that = this;
      _that.$toast({
        type: 'loading',
        message: '保存中...',
        duration: 0
      })
      let index = -1;
      index = this.address.index;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(
          _that.$store.state.domain + "web/orderv2/indb_address_list",
          _that.$qs.stringify({
            address: _that.list
          })
        )
        .then(res => {
          console.log(res, "upload then");
          _that.$toast.clear();
          _that.chongfu = 0;
          if (res.data.code == 100000) {
            _that.$toast(res.data.msg ? res.data.msg : "保存成功");
            const address = [];

            res.data.data.forEach((item, index) => {
              _that.list[index].id= item.id;
              _that.list[index].chongfu= item.chongfu;
              if (item.chongfu == 1) {
                _that.chongfu = item.chongfu
              }
              address.push({
                id: item.id,
                name: item.realname,
                mobile: item.mobile,
                areaCode: item.datavalue,
                province: item.province,
                city: item.city,
                area: item.area,
                street: item.street
              });
            })
            console.log(_that.list, "_that.list");
            localStorage.setItem('DK_ADDRESS', JSON.stringify(address));
          }else {
            _that.$dialog.alert({
              message: res.data.msg ? res.data.msg : '保存失败',
              theme: 'round-button',
            }).then(() => {
              // on close
            });
          }
        })
        .catch(err => {
          console.log(err, "upload err");
        })
    },

    /**
     * 获取所选省市县
     */
    onConfirm(e) {
      console.log("获取所选省市县");
      console.log(e);
      this.address.province = e[0].name;
      this.address.city = e[1].name;
      this.address.area = e[2].name;
      this.address.areaCode = e[2].code;
      this.address.areastr = e[0].name + "/" + e[1].name + "/" + e[2].name;
      this.showArea = false;
    },

    /**
     * 保存地址列表
     */
    save() {
      let _that = null;
      _that = this;
      _that.$toast({
        type: 'loading',
        message: '保存中...',
        duration: 0
      })

      let shibie = -1;
      shibie = 0;
      _that.list.forEach(item => {
        console.log(item);
        if (!item.address || !item.address.areaCode) {
          shibie = 1;
        }
      })
      console.log(_that.list);
      if (shibie == 1) {
        _that.$toast.clear();
        _that.$toast('请先识别地址再保存');
        return false;
      }


      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(
          _that.$store.state.domain + "web/orderv2/indb_address_list",
          _that.$qs.stringify({
            address: _that.list
          })
        )
        .then(res => {
          console.log(res);
          _that.$toast.clear();
          _that.chongfu = 0;
          if (res.data.code == 100000) {
            _that.$toast(res.data.msg ? res.data.msg : "保存成功");
            const address = [];

            res.data.data.forEach((item, index) => {
              _that.list[index].id= item.id;
              _that.list[index].chongfu= item.chongfu;
              if (item.chongfu == 1) {
                _that.chongfu = item.chongfu
              }
              address.push({
                id: item.id,
                name: item.realname,
                mobile: item.mobile,
                areaCode: item.datavalue,
                province: item.province,
                city: item.city,
                area: item.area,
                street: item.street
              });
            })
            console.log(_that.list, "_that.list");
            localStorage.setItem('DK_ADDRESS', JSON.stringify(address));
            if(_that.chongfu == 0){
              _that.onClickLeft();
            }
            // _that.onClickLeft();
          }else {
            _that.$dialog.alert({
              message: res.data.msg ? res.data.msg : '保存失败',
              theme: 'round-button',
            }).then(() => {
              // on close
            });
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    /**
     * 点击确定按钮
     */
    chongfuComfime() {
      this.onClickLeft();
    },
  },
}
</script>
<style lang="less">
  .addMoreAddress {
    width: 100%;
    min-height: ceil(100vh);
    overflow: hidden;

    .van-nav-bar .van-icon {
      color: #333;
    }

    .address_shibie {
      overflow: hidden;
      background-color: #fff;
      padding: 10px 0 60px;
      text-align: right;
      .van-field {
        .van-cell__value {
          flex: 1;
        }
      }
      .address_list_van-cell {
        .van-cell__title {
          flex: 1;
          text-align: left;
        }
      }
      .shibie_btn {
        display: flex;
        padding: 10px 16px;
        overflow: hidden;
        align-items: center;
        .shibie_btn_list {
          flex: 1;
          text-align: left;
          display: flex;
          align-items: center;
          .van-icon {
            display: block;
          }
          .remove_list {
            border: 1px solid #333;
            border-radius: 50px;
            padding: 2px;
          }
        }

        .van-button{
          padding: 0 20px;
        }
        .van-button--hairline::after{
          border-radius: 10px;
        }
      }
    }
    .save_btn {
      margin: 20px;
      overflow: hidden;
    }

    .demo_cont {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
      background-color: rgba(0, 0, 0, 0.5);
      overflow: hidden;

      .demo-animate-block {
        position: absolute;
        top: 10%;
        left: 10%;
        width: 80%;
        background-color: #fff;
        border-radius: 3px;
        padding: 10px;
        margin: -10px 0 0 -10px;
        .van-cell__title{
          width: 60px;
        }
        .van-cell__value{
          flex: 1;
        }
        .address_shibie {
          margin-top: 10px;
          overflow: hidden;
          background-color: #fff;
          padding: 10px 0;
          text-align: right;
          .van-button{
            margin-top: 10px;
            margin-right: 16px;
            padding: 0 20px;
          }
          .van-button--hairline::after{
            border-radius: 10px;
          }
        }
      }
    }
    .red {
      color: #e2280a;
    }
  }
</style>